import React from "react"
import Container from "./Container"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../common/Button"
import { Link } from "gatsby"

const DATA = {
  title: "A lab on your clients' wrist.",
  descriptions: [
    "The PNOĒ Precision app provides your clients with the most holistic and personalized health tracking experience. The PNOĒ platform then lets you monitor them every step of the way.",
  ],
  button: {
    name: "Book a demo",
    to: "/payment",
  },
}

export default function Hero() {
  return (
    <Container bleed="both" py="10rem">
      <div>
        <Container.Title width="35rem">{DATA.title}</Container.Title>
        <Container.Description width="36rem">
          {DATA.descriptions[0]}
        </Container.Description>
        <Button as={Link} to={DATA.button.to}>
          {DATA.button.name}
        </Button>
      </div>
      <Container.Image align="center">
        <StaticImage
          placeholder="blurred"
          src="../../assets/images/virtual-lab/hero.png"
          alt="phone"
          width={500}
        />
      </Container.Image>
    </Container>
  )
}

Hero.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="35rem">{DATA.title}</Container.Title>
    <Container.Description width="36rem">
      {DATA.descriptions[0]}
    </Container.Description>
    <StaticImage
      placeholder="blurred"
      src="../../assets/images/virtual-lab/hero.png"
      alt="phone"
      width={337}
    />
    <Button as={Link} to={DATA.button.to} mt={24}>
      {DATA.button.name}
    </Button>
  </Container>
)
