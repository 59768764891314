import React from "react"
import Container from "./Container"
import { StaticImage } from "gatsby-plugin-image"
import Divider from "../common/Divider"

const DATA = {
  title: "Nutrition",
  descriptions: [
    "PNOĒ Precision features the most advanced diet planner. The meal plans crafted by our experts account for all your preferences, restrictions, food intolerances, as well as calorie, fat, and carbohydrate needs. Its algorithm tracks your activity level and automatically adjusts meals down to a serving level removing all the hassle in making daily adjustments.",
  ],
}

export default function Nutrition() {
  return (
    <Container bleed="both">
      <div>
        <Container.Title width="39rem">{DATA.title}</Container.Title>
        <Container.Description width="43rem">
          {DATA.descriptions[0]}
        </Container.Description>
      </div>
      <Container.Image align="center">
        <StaticImage
          placeholder="blurred"
          src="../../assets/images/virtual-lab/nutrition.png"
          alt="phone"
          width={587}
        />
        <Divider.Light />
      </Container.Image>
    </Container>
  )
}

Nutrition.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="39rem" mb={0}>
      {DATA.title}
    </Container.Title>
    <Container.Image align="center">
      <StaticImage
        placeholder="blurred"
        src="../../assets/images/virtual-lab/nutrition.png"
        alt="phone"
        width={587}
      />
      <Divider.Light />
    </Container.Image>
    <Container.Description width="43rem" mt={40}>
      {DATA.descriptions[0]}
    </Container.Description>
  </Container>
)
