import React from "react"
import Container from "./Container"
import { StaticImage } from "gatsby-plugin-image"

const DATA = {
  title: "Nutrition, Training, Recovery, and Health, all tracked in one app.",
  descriptions: [
    "The PNOĒ Precision app tracks for the first time all four pillars of wellness: Activity, Nutrition, Recovery, and Mental Readiness. It does so by monitoring key health metrics from their wearable and other apps and merging them with their breath profile measured by the PNOĒ test to provide them 24/7 insights personalized to their metabolism.",
  ],
}

export default function TrackedTogether() {
  return (
    <Container bleed="both" py="10rem">
      <div>
        <Container.Title width="42rem">{DATA.title}</Container.Title>
        <Container.Description width="43rem">
          {DATA.descriptions[0]}
        </Container.Description>
      </div>
      <Container.Image align="center">
        <StaticImage
          placeholder="blurred"
          src="../../assets/images/virtual-lab/tracked-together.png"
          alt="phone"
          width={465}
        />
      </Container.Image>
    </Container>
  )
}

TrackedTogether.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="42rem">{DATA.title}</Container.Title>
    <StaticImage
      placeholder="blurred"
      src="../../assets/images/virtual-lab/tracked-together.png"
      alt="phone"
      width={465}
    />
    <Container.Description width="43rem" mt={24} mb={0}>
      {DATA.descriptions[0]}
    </Container.Description>
  </Container>
)
