import React from "react"
import Container from "./Container"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../common/Button"
import { Link } from "gatsby"

const DATA = {
  title: "Supercharge your clients' wearable and put a lab on their wrist.",
  descriptions: [
    "The PNOĒ Precision app calibrates your clients' wearable with their breath data and makes it able to monitor their metabolism and health like never before. Their calibrated wearable data merge with our predictive analytics to monitor the evolution of health metrics such as VO2max, fat-burning efficiency, metabolic efficiency, lung, and heart fitness.",
    "All this information is combined to measure your clients' biological age and whether their lifestyle is causing them to get younger or older.",
  ],
  button: {
    name: "Dive into the science",
    to: "/the-science",
  },
}

export default function Wearable() {
  return (
    <Container bleed="both" py="10rem">
      <div>
        <Container.Title width="42rem">{DATA.title}</Container.Title>
        <Container.Description width="43rem">
          {DATA.descriptions[0]}
        </Container.Description>
        <Container.Description width="43rem">
          {DATA.descriptions[1]}
        </Container.Description>
        <Button as={Link} to={DATA.button.to}>
          {DATA.button.name}
        </Button>
      </div>
      <Container.Image align="center">
        <StaticImage
          placeholder="blurred"
          src="../../assets/images/virtual-lab/wearable.png"
          alt="phone"
          width={337}
        />
      </Container.Image>
    </Container>
  )
}

Wearable.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="42rem" mb={24}>
      {DATA.title}
    </Container.Title>
    <StaticImage
      placeholder="blurred"
      src="../../assets/images/virtual-lab/wearable.png"
      alt="phone"
      width={337}
    />
    <Container.Description width="43rem" mt={24}>
      {DATA.descriptions[0]}
    </Container.Description>
    <Container.Description width="43rem">
      {DATA.descriptions[1]}
    </Container.Description>
    <Button as={Link} to={DATA.button.to}>
      {DATA.button.name}
    </Button>
  </Container>
)
