import React from "react"
import Container from "./Container"
import { StaticImage } from "gatsby-plugin-image"

const DATA = {
  title: "Track your clients with the most advanced monitoring platform",
  descriptions: [
    "The PNOĒ platform syncs in real-time with your clients' PNOĒ Precision account, allowing you to follow them everywhere they go. For the first time, you can monitor key health metrics such as heart rate variability, caloric burn, sleep quality, and track the food items they eat. You can also monitor 24/7 metrics calculated by PNOĒ Ai, such as their heart fitness, biological age, metabolic efficiency, and fat burn capability.",
  ],
}

export default function MonitoringPlatform() {
  return (
    <Container bleed="both" py="10rem">
      <Container.Image align="center">
        <StaticImage
          placeholder="blurred"
          src="../../assets/images/virtual-lab/monitoring-platform.png"
          alt="phone"
          width={500}
        />
      </Container.Image>
      <div>
        <Container.Title width="39rem">{DATA.title}</Container.Title>
        <Container.Description width="43rem">
          {DATA.descriptions[0]}
        </Container.Description>
      </div>
    </Container>
  )
}

MonitoringPlatform.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="39rem" mb={24}>
      {DATA.title}
    </Container.Title>
    <StaticImage
      placeholder="blurred"
      src="../../assets/images/virtual-lab/monitoring-platform.png"
      alt="phone"
      width={337}
    />
    <Container.Description width="43rem" mt={24} mb={0}>
      {DATA.descriptions[0]}
    </Container.Description>
  </Container>
)
