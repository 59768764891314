import React from "react"
import Layout from "../components/common/Layout"
import SEO from "../components/common/SEO"
import Divider from "../components/common/Divider"
import { DEVICES } from "../config"
import useWindowSize from "../hooks/useWindowSize"
import Hero from "../components/virtual-lab/Hero"
import TrackedTogether from "../components/virtual-lab/TrackedTogether"
import Wearable from "../components/virtual-lab/Wearable"
import MonitoringPlatform from "../components/virtual-lab/MonitoringPlatform"
import Training from "../components/virtual-lab/Training"
import Nutrition from "../components/virtual-lab/Nutrition"
import Recovery from "../components/virtual-lab/Recovery"
import Mind from "../components/virtual-lab/Mind"

export default function VirtualLabPage() {
  const { width } = useWindowSize()

  return (
    <Layout>
      <SEO title="Virtual Lab" />
      <Divider.Light />
      {width < DEVICES.desktop ? (
        <>
          <Hero.Mobile />
          <Divider.Light />
          <TrackedTogether.Mobile />
          <Divider.Light />
          <Wearable.Mobile />
          <Divider.Light />
          <MonitoringPlatform.Mobile />
          <Divider.Light />
          <Training.Mobile />
          <Nutrition.Mobile />
          <Recovery.Mobile />
          <Mind.Mobile />
        </>
      ) : (
        <>
          <Hero />
          <Divider.Light />
          <TrackedTogether />
          <Divider.Light />
          <Wearable />
          <Divider.Light />
          <MonitoringPlatform />
          <Divider.Light />
          <Training />
          <Nutrition />
          <Recovery />
          <Mind />
        </>
      )}
    </Layout>
  )
}
